import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { store } from "./redux/store";
import { injectStore } from "./utils/axios";

import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'


import { ThemeProvider } from "./contexts/ThemeContext";

const { PUBLIC_URL } = process.env;

injectStore(store);
let persistor = persistStore(store)
ReactDOM.render(
  <BrowserRouter basename={PUBLIC_URL}>
    <ThemeProvider>
      <PersistGate loading={null} persistor={persistor}>
       <App store={store} />
       </PersistGate>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
