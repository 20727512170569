import axios from '../../utils/axios';
import _ from 'lodash';

export const BASE_URL = '/users';

export function getAllAdmin() {
  return axios.get(`${BASE_URL}/create-admin/admins/list`);
}

export function inviteAdmin(postData) {
  let postvalues = _.cloneDeep(postData);
  return axios.post(`${BASE_URL}/invite-admin`, postvalues);
}
export function editAdmin(role,roleId,id) {
  let postvalues ={
    role:role,
    roleId:roleId
  }
  return axios.patch(`${BASE_URL}/create-admin/${id}`, postvalues);
}
export function deleteAdmin(postData) {
  let postvalues = _.cloneDeep(postData);
  return axios.post(`${BASE_URL}/create-admin/admin/delete`, postvalues);



}
