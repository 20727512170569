import { createSlice } from '@reduxjs/toolkit';

const initialUserModuleState = {
  listLoading: false,
  actionsLoading: false,
  modules:null,
  defaultRows: 10,
  entities: null,
  lastError: null,
};
export const callTypes = {
  list: 'list',
  action: 'action',
};

export const rolesSlice = createSlice({
  name: 'roles',
  initialState: initialUserModuleState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // ModulesFetched
    ModulesFetched: (state, action) => {
      const {  modules } = action.payload;
      state.error = null;
      state.modules = modules;
    },
    // AdminsFetched
    AdminsFetched: (state, action) => {
      const {  entities } = action.payload;
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    // AdminsCreated
    UserRoleCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.admin);
    },
    
    // // PreptimeUpdated
    // UserRoleUpdated: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.entities= state.entities.map((entity) => {
    //     if (entity.idAdminRole === action.payload.UserData.id || entity.id === action.payload.UserData.id) {
    //       return action.payload.UserData;
    //     }
    //     return entity;
    //   });
    // },
    // PreptimeDeleted
    UserRoleDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.idPrepTime !== action.payload.id,
      );
    },
    // Default Rows
    itemsRowsNoUpdate: (state, action) => {
      state.defaultRows = action.payload.rows;
    },
  },
});
