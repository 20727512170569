import * as requestFromServer from '../cruds/logs_adminCrud';
import { adminlogsSlice, callTypes } from '../slices/logs_adminSlice';

const { actions } = adminlogsSlice;

export const fetchAllOrderLogs = (rows,page) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllAdminLogs(rows,page)
    .then((response) => {
      const entities = response.data;
      dispatch(
        actions.logsFetched({
          totalCount: entities?.length || 0,
          entities: entities || [],
        }),
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find admin logs";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const updateDefaultRows = (rows) => (dispatch) => {
  dispatch(actions.itemsRowsNoUpdate({ rows }));
};
