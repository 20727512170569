import { createSlice } from '@reduxjs/toolkit';

const initialLocationsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  verifiedlocation: null,
  deliveryTestLog: [],
  PrepTime: null,
  locationForEdit: undefined,
  lastError: null,
  defaultRows: 10,
};
export const callTypes = {
  list: 'list',
  action: 'action',
};

export const locationsSlice = createSlice({
  name: 'locations',
  initialState: initialLocationsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // locationFetched
    locationFetched: (state, action) => {
      state.actionsLoading = false;
      state.customerForEdit = action.payload.customerForEdit;
      state.error = null;
    },
    // locationsFetched
    locationsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.verifiedlocation = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // locationCreated
    locationCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.location);
    },
    // locationUpdated
    locationUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.idMixtLocation === action.payload.location.idMixtLocation) {
          return action.payload.location;
        }
        return entity;
      });
    },
    // locationDeleted
    locationDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.idMixtLocation !== action.payload.id,
      );
    },
    // locationsDeleted
    locationsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.idMixtLocation),
      );
    },
    // locationsStatusUpdated
    locationsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.idMixtLocation) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    // verifyLocation
    verifyLocation: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.verifiedlocation = action.payload.location;
    },
    // clearLocation
    clearLocation: (state) => {
      state.actionsLoading = false;
      state.error = null;
      state.verifiedlocation = null;
    },
    // Add Delivery Test Log
    deliveryTestLog: (state, log) => {
      state.actionsLoading = false;
      state.error = null;
      state.deliveryTestLog.unshift(log.payload);
    },

    // Add locationsPrepTime
    setPrepTime: (state, obj) => {
      state.actionsLoading = false;
      state.error = null;
      state.prepTime = obj.payload;
    },
    // Default Rows
    itemsRowsNoUpdate: (state, action) => {
      state.defaultRows = action.payload.rows;
    },
  },
});
