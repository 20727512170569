import * as requestFromServer from '../cruds/adminsCrud';
import { adminSlice, callTypes } from '../slices/adminSlice';
const { actions } = adminSlice;

export const fetchAdmins = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllAdmin()
    .then((response) => {
      const entities = response.data.data;
      dispatch(
        actions.AdminsFetched({
            entities: entities || [],
        }),
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find admins";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const inviteAdmin = (UserData) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .inviteAdmin(UserData)
    .catch((error) => {
      error.clientMessage = "Can't create admin ";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const editAdmin = (role,roleId,id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .editAdmin(role,roleId,id)
    .catch((error) => {
      error.clientMessage = "Can't edit admin ";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const deleteAdmin = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteAdmin(id)
    .then(() => {
      dispatch(actions.AdminsDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete prep time";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateDefaultRows = (rows) => (dispatch) => {
  dispatch(actions.itemsRowsNoUpdate({ rows }));
};
