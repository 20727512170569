import { createSlice } from '@reduxjs/toolkit';

const initialOrderState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  defaultRows: 10,
};
export const callTypes = {
  list: 'list',
  action: 'action',
  search: 'search',
};

export const logsSlice = createSlice({
  name: 'orderlogs',
  initialState: initialOrderState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    logsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    itemsRowsNoUpdate: (state, action) => {
      state.defaultRows = action.payload.rows;
    },
  },
});
