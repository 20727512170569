import React from 'react';
import async from './components/Async';
import loadable from '@loadable/component'
// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';
// Guards
import AuthGuard from './components/guards/AuthGuard';
import Page404 from './pages/auth/Page404';
import Admins from './pages/pages/Admins/Admins';
import Logs from './pages/pages/Logs/Logs';
import Mixtdelivery from './pages/pages/Mixtdelivery/Mixtdelivery';
const ItemsPhotos = async(() =>
  import('./pages/pages/Itemsphotos/Itemsphotos'),
);
const ExceptionDates = async(() =>
  import('./pages/pages/Exceptiondates/Exceptiondates'),
);
const LoginWithGoogle =  loadable(() => import(/* webpackPreload: true */ './pages/auth/LoginWithGoogle'));
const Locations = async(() => import('./pages/pages/Locations/Locations'));
const DeliveryRadiusList = async(() => import('./pages/pages/DeliveryRadius'));
const Default = async(() => import('./pages/dashboards/Default'));
const Items = async(() => import('./pages/pages/Items/Items'));
const PrepTimes = async(() => import('./pages/pages/PrepTimes/Preptimes'));
const UserTypes = async(() => import('./pages/pages/Roles/Roles'));
const Modifiers = async(() => import('./pages/pages/Modifiers/Modifiers'));
const Campaigns = async(() => import('./pages/pages/Campaigns/Campaigns'));
const Orders = async(() => import('./pages/pages/Orders/Orders'));
const Categories = async(() => import('./pages/pages/Categories/Categories'));
const Suggestions = async(() =>
  import('./pages/pages/Suggestions/Suggestions'),
);
const routes = [
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        path: '',
        element: <LoginWithGoogle />,
      },
    ],
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Default />,
      },
    ],
  },
  {
    path: 'locations',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Locations />,
      },
    ],
  },
  {
    path: 'delivery-radius',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <DeliveryRadiusList />,
      },
    ],
  },
  {
    path: 'exception-dates',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <ExceptionDates />,
      },
    ],
  },
  {
    path: 'items',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Items />,
      },
    ],
  },
  {
    path: 'items-photos',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <ItemsPhotos />,
      },
    ],
  },
  {
    path: 'prep-times',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <PrepTimes />,
      },
    ],
  },

  {
    path: 'modifier-visibility',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Modifiers />,
      },
    ],
  },
  {
    path: 'campaigns',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Campaigns />,
      },
    ],
  },
  {
    path: 'orders',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Orders />,
      },
    ],
  },
  {
    path: 'categories',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Categories />,
      },
    ],
  },
  {
    path: 'suggestions',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Suggestions />,
      },
    ],
  },
  {
    path: 'usertype',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <UserTypes />,
      },
    ],
  },
  {
    path: 'users',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Admins />,
      },
    ],
  },
  {
    path: 'logs',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Logs />,
      },
    ],
  },
  {
    path: 'delivery',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Mixtdelivery />,
      },
    ],
  },

  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
