import axios from '../../utils/axios';
import _ from 'lodash';

export const BASE_URL = '/orders';

export function getAmount(postData) {
    let postvalues = _.cloneDeep(postData);
  return axios.post(`${BASE_URL}/settings/type`,postvalues);
}

export function postAmount(postData) {
  let postvalues = _.cloneDeep(postData);
  return axios.post(`${BASE_URL}/settings`, postvalues);
}
export function updateAmount(postData,id) {
  let postvalues = _.cloneDeep(postData);
  return axios.patch(`${BASE_URL}/settings/${id}`, postvalues);
}
