import * as requestFromServer from '../cruds/rolesCrud';
import { rolesSlice, callTypes } from '../slices/rolesSlice';
import _ from "lodash";
const { actions } = rolesSlice;

export const fetchModules = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllModules()
    .then((response) => {
      const modules = _.sortBy(response.data.data, ['name']);
      dispatch(
        actions.ModulesFetched({
            modules: modules || [],
        }),
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find modules";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchRoles = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllRole()
    .then((response) => {
      const entities =response.data.data;
      dispatch(
        actions.AdminsFetched({
            entities: entities || [],
        }),
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find admins";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const createRoles = (UserData) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createRole(UserData)
    .then((response) => {
      let admin = response.data.data;
      dispatch(actions.UserRoleCreated({ admin }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create admin ";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateRoles = (UserData) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateRole(UserData)
    .then(() => {
      
    })
    .catch((error) => {
      error.clientMessage = "Can't update admin role";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateDefaultRows = (rows) => (dispatch) => {
  dispatch(actions.itemsRowsNoUpdate({ rows }));
};
