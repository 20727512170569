import axios from 'axios';
import { key_256, iv } from '../redux/clientKeys';
import aesjs from 'aes-js';
import NodeRSA from 'node-rsa';
var CryptoJS = require('crypto-js');

let store;

export const injectStore = (_store) => {
  store = _store;
};
const DEFAULT_ACCESS_TOKEN =
  '84ERMEaiFNnREq74rqSMrwGd983I7072n4wDwIYSnda1ronb0hGGp';

const starter_key = new NodeRSA({ b: 2048 });

const starter_public = starter_key.exportKey('pkcs1-public');

const key = new NodeRSA();
key.setOptions({
  encryptionScheme: 'pkcs1_oaep',
});

function _arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function _base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

const rsaEncryption = () => {
  const buffer = {
    key: _arrayBufferToBase64(key_256),
    iv: _arrayBufferToBase64(iv),
  };
  return key.encrypt(buffer, 'base64');
};

const encryptData = (data) => {
  var textBytes = aesjs.utils.utf8.toBytes(JSON.stringify(data));
  var aesOfb = new aesjs.ModeOfOperation.cbc(key_256, iv);
  var encryptedBytes = aesOfb.encrypt(aesjs.padding.pkcs7.pad(textBytes));
  var encryptedBase64 = _arrayBufferToBase64(encryptedBytes);
  const sendData = {
    value: encryptedBase64,
    handler: rsaEncryption(),
  };
  return sendData;
};

const rsaDecryption = (data, validKey) => {
  const buffer = validKey.decrypt(data);
  return JSON.parse(buffer);
};

const decryptData = (data, rsaData) => {
  var aesOfb = new aesjs.ModeOfOperation.cbc(
    Buffer.from(_base64ToArrayBuffer(rsaData.key)),
    Buffer.from(_base64ToArrayBuffer(rsaData.iv)),
  );
  var decryptedBytes = aesjs.padding.pkcs7.strip(
    aesOfb.decrypt(Buffer.from(_base64ToArrayBuffer(data))),
  );
  var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
  const parsedData = JSON.parse(decryptedText);

  return parsedData;
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
const RequestHandler = (request) => {
  // Token will be dynamic so we can use any app-specific way to always
  // fetch the new token before making the call
  var token = window.localStorage.getItem('accessToken') || '';
  if (token) {
    request.headers.Authorization = 'Bearer ' + token;
  }
  if (request.url === '/users/create-admin') {
    return request;
  }
  if (request.url === '/users/verify-admin') {
    request.data = {
      public_key: starter_public,
    };
    return request;
  }
  if (
    request.url === '/menus/items-photos/upload' ||
    request.url === '/orders/campaigns/upload'
  ) {
    request.headers.skipencrypt = true;
    return request;
  }

  if (request.data) {
    let userObjState = store.getState().auth.authDate;

    const base64Token = Buffer.from(DEFAULT_ACCESS_TOKEN).toString('base64');
    // ENCRYPT DATA WITH DECRYPTED OBJECT
    let decryptedData = CryptoJS.AES.decrypt(userObjState, base64Token);
    decryptedData = decryptedData.toString(CryptoJS.enc.Utf8);

    // CONVERT BASE64 STRING TO OBJECT
    let authDateObj = JSON.parse(
      Buffer.from(decryptedData, 'base64').toString('ascii'),
    );

    key.importKey(authDateObj.server_key, 'pkcs1-public');
    const encryptedLocation = encryptData(request.data);
    request.data = encryptedLocation;
  }
  return request;
};

const ResponseHandler = (response) => {
  const myURL = new URL(response.request.responseURL);
  const respPathname = myURL.pathname;
  if (response.status === 401) {
    window.location = '/';
    return response;
  }
  if (
    respPathname === '/users/create-admin' ||
    respPathname === '//users/create-admin' ||
    respPathname === '/create-admin' ||
    respPathname === '//create-admin' ||
    respPathname === '/menus/items-photos/upload' ||
    respPathname === '//menus/items-photos/upload' ||
    respPathname === '/orders/campaigns/upload' ||
    respPathname === '//orders/campaigns/upload'
  ) {
    return response;
  }
  if (
    respPathname === '/users/verify-admin' ||
    respPathname === '//users/verify-admin' ||
    respPathname === '/verify-admin' ||
    respPathname === '//verify-admin'
  ) {
    if (response.data) {
      const { value, handler } = response.data;
      const rsaData = rsaDecryption(handler, starter_key);
      const parsedData = decryptData(value, rsaData);
      response.data = parsedData;
      return response;
    }
  }
  if (response.data) {
    let userObjState = store.getState().auth.authDate;

    const base64Token = Buffer.from(DEFAULT_ACCESS_TOKEN).toString('base64');
    // ENCRYPT DATA WITH DECRYPTED OBJECT
    let decryptedData = CryptoJS.AES.decrypt(userObjState, base64Token);
    decryptedData = decryptedData.toString(CryptoJS.enc.Utf8);

    // CONVERT BASE64 STRING TO OBJECT
    let authDateObj = JSON.parse(
      Buffer.from(decryptedData, 'base64').toString('ascii'),
    );

    key.importKey(authDateObj.client_key, 'pkcs1-private');
    const { handler, value } = response.data;
    const rsaData = rsaDecryption(handler, key);
    const parsedData = decryptData(value, rsaData);
    response.data = parsedData;
  }
  return response;
};

const errorHandler = (error) => {
  if (error.response.status === 401) {
    window.location = '/';
  }
  return Promise.reject(
    (error.response && error.response.data) || 'Something went wrong',
  );
};

axiosInstance.interceptors.request.use(
  (request) => RequestHandler(request),
  (error) => errorHandler(error),
);

axiosInstance.interceptors.response.use(
  (response) => ResponseHandler(response),
  (error) => errorHandler(error),
);

export default axiosInstance;
