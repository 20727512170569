import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { locationsSlice } from './slices/locationsSlice';
import { exceptiondateSlice } from './slices/exceptiondateSlice';
import { itemsSlice } from './slices/itemsSlice';
import { FBReducer } from '../contexts/FirebaseAuthContext';
import { preptimesSlice } from './slices/preptimesSlice';
import { itemPhotosSlice } from './slices/itemsPhotoSlice';
import { modifierVisibilitySlice } from './slices/modifierVisibilitySlice';
import { campaignsSlice } from './slices/campaignsSlice';
import { ordersSlice } from './slices/ordersSlice';
import { logsSlice } from './slices/logsSlice';
import { categoriesSlice } from './slices/categoriesSlice';
import { suggestionsSlice } from './slices/suggestionsSlice';
import { rolesSlice } from './slices/rolesSlice';
import { adminSlice } from './slices/adminSlice';
import { adminlogsSlice } from './slices/logs_adminSlice';
import { deliveryamountSlice } from './slices/deliveryamountSlice';
//const rootPersistConfig = {
//  key: 'root',
//  storage: storage,
//  blacklist: ['auth']
//}

const authPersistConfig = {
  key: 'auth',
  storage: storage,
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, FBReducer.reducer),
  locations: locationsSlice.reducer,
  exceptiondate: exceptiondateSlice.reducer,
  items: itemsSlice.reducer,
  preptimes: preptimesSlice.reducer,
  itemPhotos: itemPhotosSlice.reducer,
  modifiers: modifierVisibilitySlice.reducer,
  campaigns: campaignsSlice.reducer,
  orders: ordersSlice.reducer,
  orderlogs: logsSlice.reducer,
  categories: categoriesSlice.reducer,
  suggestions: suggestionsSlice.reducer,
  roles: rolesSlice.reducer,
  admins: adminSlice.reducer,
  adminlogs: adminlogsSlice.reducer,
  deliveryamount: deliveryamountSlice.reducer,
  
});

//const persistedReducer = persistReducer({key:'root'}, rootReducer)

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
