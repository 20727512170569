import React from "react";
import { useLocation } from "react-router-dom";

import ReduceChildRoutes from "./reduceChildRoutes";

const SidebarNavList = (props) => {
  const { pages, depth, handleDrawerToggle } = props;
  const router = useLocation();
  const currentRoute = router.pathname;
  
  const childRoutes = pages.reduce(
    (items, page) =>
    ReduceChildRoutes({
        items,
        page,
        currentRoute,
        depth,
        handleDrawerToggle,
      }),
    []
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;

// currentState.user.role_permissions.length === 0 ||  currentState.user.role_permissions.find(item.title)
// ?