import axios from '../../utils/axios';

export const BASE_URL = '/users/admin-entry';

export function getAllAdminLogs(rows,page) {
  return axios.post(`${BASE_URL}/fetch`,{
    limit:rows,
    page:page
  });
}
