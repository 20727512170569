import * as requestFromServer from '../cruds/deliveryamountCrud';
import { deliveryamountSlice, callTypes } from '../slices/deliveryamountSlice';
const { actions } = deliveryamountSlice;

export const getAmount = (UserData) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAmount(UserData)
    .then((response) => {
      const amount = response.data.data;
      if(UserData.settingType==="mixt_minimum_delivery")
      dispatch(
        actions.AmountFetched({
            amount: amount,
        }),
      );
      else if(UserData.settingType==="doordash_minimum_delivery")
      dispatch(
        actions.DoorDashFetched({
          amount: amount,
        }),
      );
    })
    .catch((error) => {
      error.clientMessage = "Error post";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const postAmount = (UserData, type) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  console.log('UserData',UserData,"tpye",type)
  return requestFromServer
    .postAmount(UserData)
    .then((response) => {
      const amount = response.data.data;
      if (type === "amount")
      dispatch(
        actions.AmountPost({
            amount: amount,
        }),
      );
      else if (type === "door")
        dispatch(
          actions.DoorDashPost({
            amount: amount,
          }),
        );
    })
    .catch((error) => {
      error.clientMessage = "Error patch";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const updateAmount = (UserData, id, type) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateAmount(UserData, id)
    .then((response) => {
      console.log(response, "Response update")
      if (response.data.data === "success") {
        if (type === "amount")
      dispatch(
        actions.AmountPost({
            amount: UserData,
        }),
      );
        else if (type === "door")
          dispatch(
            actions.DoorDashPost({
              amount: UserData,
            }),
          );
      }
    })
    .catch((error) => {
      error.clientMessage = "Error patch";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};