import React, { useState,useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EditAdmins from "./EditAdmins";
import * as actions from "../../../redux/actions/adminsActions";
import moment from "moment";
import { Formik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  TableCell,
  Typography,
} from "@mui/material";


export default function RowEdit({
  row,
  handleSubmit,
  actionsLoading,
}) {
  const [rowEmail, setrowEmail] = useState(0);
  const [action, setAction] = useState("");
  const [roles, setRoles] = useState([]);
  var date=moment.unix(row.inviteStatus<2 ? row.userUpdatedAt : row.inviteUpdatedAt).format('DD/M/YYYY - HH:mm:ss');
  
  const [InviteModalOpen, setInviteModalOpen] = useState(false);
  const dispatch = useDispatch();  
  const handleInviteOpen = () => setInviteModalOpen(true);
  const handleInviteClose = () => setInviteModalOpen(false);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.roles }),
    shallowEqual,
  );
  const { entities } = currentState;
  useEffect(()=>{
    if(entities){var roles=entities.filter(
      (role)=>
      row.roleId===role.idAdminRole
    )
    setRoles(roles[0])}
  },[entities,row.roleId])


  const handleDelete=()=>{
    setrowEmail(row.email);
    var data={};
    if(row.idAdminUser)
    data={...data,
      adminUserId:row.idAdminUser
    }
    if(row.idInvitation)
    data={...data,
      invitationId:row.idInvitation
    }
    

    dispatch(actions.deleteAdmin(data)).then(()=>{
      dispatch(actions.fetchAdmins({}))
    })

  }

  return (
    (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={row}
          onSubmit={(values) => {
            handleSubmit(values, () => () => handleInviteClose());
          }}
        >
          {({ handleSubmit, values, setFieldValue }) => (
        <>
          <TableCell align="left">
            <span>
              <Typography
                textOverflow="ellipsis"               
                overflow="hidden"
                whiteSpace="nowrap"
              >
             {row.email}
              </Typography>
            </span>
          </TableCell>
          <TableCell align="left">
            <span>
              <Typography
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
              >
               {
                roles&&roles.hasOwnProperty("rolePermissions")&&roles.rolePermissions?roles.rolePermissions.map((role,index)=>{
                    return index===0?role.adminModuleName:","+role.adminModuleName
                })
                :row.roleId===0?"All Modules":row.roleId
               }
              </Typography>
            </span>
          </TableCell> 
          <TableCell align="left">
            <Box mr={2}>
              <span> 
                {row.inviteStatus !== 2 ? "Accepted" : "Not Accepted"}
              </span>
              </Box>
          </TableCell>
          <TableCell align="left">
                  <span> 
                    {date}
                  </span>
              </TableCell>         
          <TableCell padding="none" align="right">
            <Box mr={2}>
              {row.inviteStatus !== 2  ? 
              <Button
              variant="contained"
              color="primary"
              sx={{ color: "white" }}
              onClick={() => {
                handleInviteOpen();
                setAction("edit")
              }}
              style={{
                marginRight:"8px",
                cursor: "pointer",
              }}
            >
              Edit
            </Button>
              :
              <Button
                variant="contained"
                color="primary"
                sx={{ color: "white" }}
                onClick={() => {
                  handleInviteOpen();
                  setAction("")
                }}
                style={{
                  marginRight:"8px",
                  cursor: "pointer",
                }}
              >
                Re-Invite
              </Button>
               }
              <Button
                variant="contained"
                color="primary"
                sx={{ color: "white" }}
                onClick={() => {
                  handleDelete();
                }}
                style={{
                  marginRight:"8px",
                  cursor: "pointer",
                }}
              >
                {actionsLoading && rowEmail===row.email ? (
                        <CircularProgress size={20} sx={{ color: "white" }} />
                      ) : (
                        "Delete"
                      )}
              </Button>
            </Box>
          </TableCell>
            <EditAdmins
              action={action}
              entity={row}
              open={InviteModalOpen}
              handleClose={handleInviteClose}
              handleSubmit={handleSubmit}
              actionsLoading={actionsLoading}
            />
        </>
        )}
        </Formik>
      </>
    )
  );
}
