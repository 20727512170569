import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions/adminsActions";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  Button,
  CircularProgress,
  Box,
  Grid,
  Modal,
  TextField,
  Typography,
  
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from 'yup';



export default function EditAdmins(props) {
  const initialValues = {
    emailId: props.entity.email,
    roleId:props.entity.roleId,
  };
  
  const dispatch = useDispatch();


  const { currentState } = useSelector(
    (state) => ({ currentState: state.admins }),
    shallowEqual
  );
  const { actionsLoading } = currentState;

  const { currentstate } = useSelector(
    (state) => ({ currentstate: state.roles }),
    shallowEqual
  );

  const style = {
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "85%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "100%", md: "50%" },
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  
  const handleModal = () => {
    props.handleClose()
  }


  const validateSchema = Yup.object().shape({
    // emailId: Yup.string().required('Required'),
    roleId: Yup.number().required('Required'),
  });


  const onSubmit=(values)=>{
    if(props.action==="edit")
    {
      var role=currentstate.entities[values.roleId]
      dispatch(actions.editAdmin(role.name,role.idAdminRole,props.entity.idAdminUser)).then(()=>props.handleSubmit(handleModal()))
     
      }
    else
      dispatch(actions.editAdmin(values.emailId,values.roleId,props.entity.idAdminUser)).then(()=>props.handleSubmit(handleModal()))

      
  }

  return (
    <Modal
      open={props.open}
      onClose={handleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ bgcolor: "transparent" }}
    >
      <Box item sx={style}>
        <Typography id="modal-modal-title" sx={{ mt: 2 }}>
           Re-Invite Admin
        </Typography>
        <Formik
          enableReinitialize ={true}
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={async (values) => {
            onSubmit(values);
          }}
        >
          {({ handleSubmit, values, setFieldValue, isValid, errors, touched }) => (
            <Form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={12}
          justifyContent="center"
          sx={{ mt: 1, pt: 1,  }}
        >
   
          <Grid item>
            <Grid container direction="row">     
              <Grid item >
                <TextField
                  id="standard-field-input"
                  label="Email"
                  disabled
                  value={props.entity.email}
                  sx={{ pb: 3, mb: 3 }}
                />     
              </Grid>
              <Grid item >
              <FormControl sx={{ mx: 2, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">Select Role</InputLabel>
                <Select
                  value={values.roleId}
                  label="Select Role"
                  onChange={(newValue) => {
                    setFieldValue("roleId", newValue.target.value);
                  }}
                >
                  {
                  currentstate.entities && currentstate.entities.map((role,index)=>{
                    return(
                      <MenuItem key={index} value={index} >{role.name}</MenuItem>
                    )
                  })
                  }
                </Select>
              </FormControl>
              </Grid>
          </Grid>
        </Grid>
        </Grid>
        <Grid
          container
          alignItems="right"
          justifyContent="right"
          sx={{  mb: 4 }}
        >
          <Button
            type="submit"
            variant="contained"
            color="success"
            sx={{ mt: 2, color: "white" }}
            className={isValid ? "" : "disabled-btn"}            
          >
            {actionsLoading ? (
              <CircularProgress size={25} sx={{ color: "white" }} />
            ) : (
              props.action!=="edit"?"Invite":"Edit"
            )}
          </Button>
        </Grid>
         </Form>
         )}
       </Formik>
      </Box>
    </Modal>
  );
}
