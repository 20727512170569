import { createSlice } from '@reduxjs/toolkit';
//import _ from 'lodash';

const initialState = {
  listLoading: true,
  actionsLoading: false,
  searchLoading: false,
  totalCount: 0,
  defaultRows: 10,
  entities: [],
  URL: '',
};

export const callTypes = {
  list: 'list',
  action: 'action',
  search: 'search',
};

export const modifierVisibilitySlice = createSlice({
  name: 'modifiers',
  initialState: initialState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.search) {
        state.searchLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.searchLoading = false;
      state.listLoading = false;
      state.actionsLoading = false;
    },

    fetchedModifierVisibility: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // Default Rows
    itemsRowsNoUpdate: (state, action) => {
      state.defaultRows = action.payload.rows;
    },

    modifierVisibilityUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (
          entity.idModifierGroup ===
          action.payload.modifierVisibility.idModifierGroup
        ) {
          return action.payload.modifierVisibility;
        }
        return entity;
      });
    },

    searchItemModifier: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.searchLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
  },
});
