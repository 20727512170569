import { createSlice } from '@reduxjs/toolkit';

const initialUserState = {
  listLoading: false,
  actionsLoading: false,
  defaultRows: 10,
  entities: null,
  lastError: null,
};
export const callTypes = {
  list: 'list',
  action: 'action',
};

export const adminSlice = createSlice({
  name: 'admins',
  initialState: initialUserState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // UsersFetched
    AdminsFetched: (state, action) => {
      const {  entities } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entities = entities;
    },
    // AdminCreated
    AdminCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.data);
    },
    
    // AdminsUpdated
    AdminsUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.email === action.payload.data.email) {
          return action.payload.data;
        }
        return entity;
      });
    },
    // AdminsDeleted
    AdminsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => action.payload.id.adminUserId? el.idAdminUser !== action.payload.id.adminUserId
         : el.idInvitation !== action.payload.id.invitationId
      );
    },
    // Default Rows
    itemsRowsNoUpdate: (state, action) => {
      state.defaultRows = action.payload.rows;
    },
  },
});
