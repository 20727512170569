import {
  Button, CircularProgress, Divider as MuiDivider,
  Grid, TextField, Typography
} from '@mui/material';
import { spacing } from '@mui/system';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import * as actions from '../../../redux/actions/deliveryamountActions';
const Divider = styled(MuiDivider)(spacing);


function Mixtdelivery() {
  const [editAmount, setEditAmount] = useState(false)
  const [edit, setEdit] = useState(false)
  const [choice, setChoice] = useState("")
  const navigate = useNavigate();

  const { userState } = useSelector(
    (state) => ({ userState: state.auth }),
    shallowEqual,
  );
  const { amountState } = useSelector(
    (state) => ({ amountState: state.deliveryamount }),
    shallowEqual,
  );

  const dispatch = useDispatch();
  const initialValues = {
    amount: amountState.amount && amountState.amount.hasOwnProperty('settingValue') && amountState.amount.settingValue ? parseInt(amountState.amount.settingValue) : '',
    door_dash: amountState.door_dash && amountState.door_dash.hasOwnProperty('settingValue') && amountState.door_dash.settingValue ? parseInt(amountState.door_dash.settingValue) : '',
  };
  const validateSchema = Yup.object().shape({
    amount: editAmount && Yup.number().required('Required'),
    door_dash: editAmount && Yup.number().required('Required'),
  });
  const onSubmit = async (values) => {
    if (editAmount) {
      if (amountState.amount && amountState.amount.hasOwnProperty('settingValue') && amountState.amount.settingValue) {
        await dispatch(actions.updateAmount({
          settingType: "mixt_minimum_delivery",
          settingValue: values.amount.toString()
        }, amountState.amount.idSettings, choice));
      }
      else {
    dispatch(actions.postAmount({
          settingType: "mixt_minimum_delivery",
          settingValue: values.amount.toString()
        }), choice);
      }
      setEditAmount(!editAmount)
    }
    else if (edit) {
      if (amountState.door_dash && amountState.door_dash.hasOwnProperty('settingValue') && amountState.door_dash.settingValue) {
        await dispatch(actions.updateAmount({
          settingType: "doordash_minimum_delivery",
          settingValue: values.door_dash.toString()
        }, amountState.door_dash.idSettings, choice));
      }
      else {
        dispatch(actions.postAmount({
          settingType: "doordash_minimum_delivery",
          settingValue: values.door_dash.toString()
        }, choice));
  }
      setEdit(false)
    }
    else if (choice === "amount")
      setEditAmount(!editAmount)
    else if (choice === "door")
      setEdit(!edit)

    setChoice("")
  }
  useEffect(() => {
    console.log(edit, "Edit", editAmount)
  }, [edit, editAmount])
  useEffect(() => {
    if (userState.isAuthenticated) {
      dispatch(actions.getAmount({ settingType: "mixt_minimum_delivery" }));
      dispatch(actions.getAmount({ settingType: "doordash_minimum_delivery" }));
    } else {
      navigate('/');
    }
  }, [dispatch, userState.isAuthenticated, navigate]);
  return (
    <React.Fragment>
      <Helmet title="Mixt Minimum Delivery Settings" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {'Welcome back'},{' '}
            {userState && userState.user && userState.user.displayName
              ? userState.user.displayName
              : 'Admin'}
          </Typography>
          <Typography variant="subtitle1" display="inline" sx={{ ml: 4 }}>
            {moment().format('dddd, DD MMMM, YYYY')}
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} sx={{ pb: 2 }} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <>
            {amountState.listLoading ? (
              <Grid container direction="row" justifyContent="center">
                <CircularProgress sx={{ color: 'white' }} />
              </Grid>
            ) : (
              <Grid justifyContent="space-between" container spacing={10} columns={12}>
                <Grid item>
                  <Grid sx={{ mt: 0, pt: 4 }}>
                    <Typography variant="h4" gutterBottom display="inline">
                      Mixt Minimum Amounts Setting
                    </Typography>
                  </Grid>
                  <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validateSchema}
                    onSubmit={async (values) => {
                      onSubmit(values);
                    }}
                  >
                    {({ handleSubmit, values, setFieldValue, isValid, errors, touched }) => (
                      <Form onSubmit={handleSubmit}>
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="flex-start"
                        >
                          <Grid item>
                          <Grid container direction="row" justifyContent="left" alignItems="center" sx={{ my: 2 }} spacing={15} >
                          <Grid item>
                            <Typography variant="h6">
                              Mixt Delivery Minimum Amount:
                            </Typography>
                          </Grid>
                            {!editAmount ?
                            (<Grid item>
                              <Typography
                                textOverflow="ellipsis"
                                overflow="hidden"
                                whiteSpace="nowrap"
                              >
                                {values.amount ? values.amount : "Amount"}
                              </Typography>

                            </Grid>
                            ) :

                          <Grid item>
                                <TextField
                                  id="standard-field-input2"
                                  label="Amount"
                                  autoComplete="current-field"
                                  error={!isValid ? true : false}
                                  value={values.amount}
                                  onChange={(newValue) => {
                                    setFieldValue("amount", newValue.target.value);
                                  }}
                                />
                          </Grid>
                          }
                          <Grid item>
                            <Button
                              type="submit"
                              variant="contained"
                                color={!editAmount ? "primary" : "success"}
                              sx={{ color: "white" }}
                              className={isValid ? "" : "disabled-btn"}
                                onClick={() => setChoice("amount")}
                            >
                                {amountState.actionsLoading && choice === "amount" ? (
                                <CircularProgress size={25} sx={{ color: "white" }} />
                                ) : (!editAmount ? "Edit"
                                : amountState.amount && amountState.amount.hasOwnProperty('settingValue') && amountState.amount.settingValue ? "Update" : "Add"
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                          </Grid>
                          <Grid item>
                          <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ my: 2 }} spacing={10} >
                            <Grid item>
                              <Typography variant="h6" >
                                DoorDash Minimum Delivery Amount:                                
                              </Typography>
                            </Grid>
                            {!edit ?
                              (<Grid item>
                                <Typography
                                  textOverflow="ellipsis"
                                  overflow="hidden"
                                  whiteSpace="nowrap"
                                >
                                  {values.door_dash ? values.door_dash : "Amount"}
                                </Typography>

                              </Grid>
                              ) :

                              <Grid item>
                                <TextField
                                  id="standard-field-input"
                                  label="Amount"
                                  autoComplete="current-field"
                                  error={!isValid ? true : false}
                                  value={values.door_dash}
                                  onChange={(newValue) => {
                                    setFieldValue("door_dash", newValue.target.value);
                                  }}
                                />
                              </Grid>
                            }
                            <Grid item>
                              <Button
                                type="submit"
                                variant="contained"
                              color={!edit ? "primary" : "success"}
                              sx={{ color: "white" }}
                              className={isValid ? "" : "disabled-btn"}
                                onClick={() => setChoice("door")}
                            >
                                {amountState.actionsLoading && choice === "door" ? (
                                <CircularProgress size={25} sx={{ color: "white" }} />
                              ) : (!edit ? "Edit"
                                  : amountState.amount && amountState.amount.hasOwnProperty('doordash_minimum_delivery') && amountState.amount.doordash_minimum_delivery ? "Update" : "Add"
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            )}
          </>
          {/* )} */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Mixtdelivery;
