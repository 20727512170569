import * as React from 'react';
import { Navigate,useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import Page404 from '../../pages/auth/Page404';
import navItems from '../../components/sidebar/dashboardItems';
// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { userState } = useSelector(
    (state) => ({ userState: state.auth }),
    shallowEqual,
  );
  const router = useLocation();
  const currentRoute = router.pathname;
  const routeTitle=navItems[0].pages.find(page => page.href === currentRoute).title;
  

  const { isAuthenticated, isInitialized } = userState;
  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/" />;
  }
  if(userState.user.role_permissions.length ===0
    ||
   userState.user.role_permissions.find(perms=>perms.adminModuleName === routeTitle.trim()))
  return <React.Fragment>{children}</React.Fragment>;
  else
    return <Page404 />;
}

export default AuthGuard;
