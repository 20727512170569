import { createSlice } from '@reduxjs/toolkit';

const initialItemState = {
  listLoading: false,
  actionsLoading: false,
  actionSync: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  defaultRows: 10,
};
export const callTypes = {
  list: 'list',
  action: 'action',
  search: 'search',
  sync: 'sync',
};

export const itemsSlice = createSlice({
  name: 'items',
  initialState: initialItemState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionSync = false;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.sync) {
        state.actionSync = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // locationsFetched
    itemsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.actionSync = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // exceptiondateUpdated
    itemUpdated: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.actionSync = false;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.idMenuItem === action.payload.item.idMenuItem) {
          return action.payload.item;
        }
        return entity;
      });
    },
    bulkUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // Default Rows
    itemsRowsNoUpdate: (state, action) => {
      state.defaultRows = action.payload.rows;
    },
  },
});
