import {
  MapPin,
  Calendar,
  List,
  Clock,
  Eye,
  Image,
  Map,
  Square,
  UserCheck,
  User,
  Database,
  Truck
} from 'react-feather';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
//Image

const pagesSection = [
  /* Home, 
  The Dashboard view can be removed from the menu for now. 10 Aug,2022 Slack
  {
    href: '/dashboard',
    icon: Home,
    title: 'Dashboard',
  },
  */
  {
    href: '/locations',
    icon: MapPin,
    title: 'Locations',
  },
  {
    href: '/delivery-radius',
    icon: Map,
    title: 'Delivery Radius',
  },
  {
    href: '/exception-dates',
    icon: Calendar,
    title: 'Exception Dates',
  },
  {
    href: '/items',
    icon: List,
    title: 'Items',
  },
  {
    href: '/items-photos',
    icon: Image,
    title: 'Item Photos',
  },
  {
    href: '/prep-times',
    icon: Clock,
    title: 'Prep Times',
  },
  {
    href: '/modifier-visibility',
    icon: Eye,
    title: 'Modifier Visibility',
  },
  {
    href: '/campaigns',
    icon: PhonelinkIcon,
    title: 'Campaign Builder',
  },
  {
    href: '/orders',
    icon: ListAltIcon,
    title: 'Orders',
  },
  {
    href: '/categories',
    icon: CheckBoxIcon,
    title: 'Catering Categories',
  },
  {
    href: '/suggestions',
    icon: Square,
    title: 'Suggestions Items',
  },
  {
    href: '/usertype',
    icon: UserCheck,
    title: 'User Roles/Permissions',
  },
  {
    href: '/users',
    icon: User,
    title: 'Users',
  },
  {
    href: '/logs',
    icon: Database,
    title: 'Logs',
  },
  {
    href: '/delivery',
    icon: Truck,
    title: 'Mixt Minimum Delivery',
  },
];
const navItems = [
  {
    title: 'Pages',
    pages: pagesSection,
  },
];

export default navItems;
