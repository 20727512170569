import { createContext, useEffect, useState } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import navItems from '../components/sidebar/dashboardItems';
import {
  getAuth,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signOut as dSignOut,
} from 'firebase/auth';
import { Snackbar, Alert } from '@mui/material';
import axios from '../utils/axios';

import { firebaseConfig } from '../config';
var CryptoJS = require('crypto-js');

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  authDate: null,
};
const DEFAULT_ACCESS_TOKEN =
  '84ERMEaiFNnREq74rqSMrwGd983I7072n4wDwIYSnda1ronb0hGGp';

export const FBReducer = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    INITIALIZE: (state, action) => {
      let authDate = {};
      authDate.server_key = action.payload.server_key;
      authDate.client_key = action.payload.client_key;
      let userObj = action.payload.user;
      if (userObj) {
        delete userObj.uid;
      }
      //EncryptReduxAuth
      // CONVERT DATA TO BASE 64 STRING
      let base64Data = Buffer.from(JSON.stringify(authDate)).toString('base64');
      // CONVERT THE DEFAULT ACCESS TOKEN TO BASE64
      const base64Token = Buffer.from(DEFAULT_ACCESS_TOKEN).toString('base64');
      state.isAuthenticated = action.payload.isAuthenticated;
      state.isInitialized = true;
      state.user = action.payload.user;
      state.authDate = CryptoJS.AES.encrypt(base64Data, base64Token).toString();
    },
    SIGN_OUT: (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
      state.server_key = null;
      state.client_key = null;
    },
  },
});

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  // const [setProfile] = useState();
  const [signCheck, setSignCheck] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const { actions } = FBReducer;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const { userObjState } = useSelector(
    (state) => ({ userState: state.auth }),
    shallowEqual,
  );
  let userState = {};
  userState.isAuthenticated = false;
  userState.user = null;
  userState.server_key = null;
  userState.client_key = null;

  if (userObjState) {
    const base64Token = Buffer.from(DEFAULT_ACCESS_TOKEN).toString('base64');
    // ENCRYPT DATA WITH DECRYPTED OBJECT
    let decryptedData = CryptoJS.AES.decrypt(userObjState, base64Token);
    decryptedData = decryptedData.toString(CryptoJS.enc.Utf8);

    // CONVERT BASE64 STRING TO OBJECT
    let authDateObj = JSON.parse(
      Buffer.from(decryptedData, 'base64').toString('ascii'),
    );
    userState.isAuthenticated = userObjState.isAuthenticated;
    userState.isInitialized = userObjState.isInitialized;
    userState.user = authDateObj.user;
    userState.server_key = authDateObj.server_key;
    userState.client_key = authDateObj.client_key;
  }

  useEffect(
    () => {
      if (!signCheck) {
        onAuthStateChanged(auth, async (user) => {
          
          if (user) {
            localStorage.setItem('accessToken', user.accessToken);
            const accessToken = window.localStorage.getItem('accessToken');
            if (accessToken && !userState.isAuthenticated && false) {
              await axios
                .post('/users/verify-admin')
                .then((response) => {
                  const { server_key, client_key } = response?.data;
                  // setProfile(null);
                  dispatch(
                    actions.INITIALIZE({
                      isAuthenticated: true,
                      user: {
                        email: user.email,
                        uid: user.uid,
                        displayName: user.displayName,
                        avatar: user.photoURL,
                      },
                      server_key,
                      client_key,
                    }),
                  );
                })
                .catch((err) => {});
            }
          } else {
            dispatch(
              actions.INITIALIZE({
                isAuthenticated: false,
                user: null,
                server_key: null,
                client_key: null,
                access_token: null,
              }),
            );
          }
        });
      }
    },
    // eslint-disable-next-line
    [],
  );
  const signInWithGoogle = async (code) => {
    setSignCheck(true);
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider).then(async (user) => {
      if (user.operationType === 'signIn') {
        if (code === null) {
          localStorage.setItem('accessToken', user.user.accessToken);
          const accessToken = window.localStorage.getItem('accessToken');
          if (accessToken) {
            await axios
              .post('/users/verify-admin')
              .then((response) => {
                const { server_key, client_key } = response?.data;
                // setProfile(null);
                
                auth.currentUser.getIdTokenResult().then((idTokenResult) =>{
                dispatch(
                  actions.INITIALIZE({
                    isAuthenticated: true,
                    user: {
                      email: user.user.email,
                      uid: user.user.uid,
                      displayName: user.user.displayName,
                      avatar: user.user.photoURL,
                      role:idTokenResult.claims.user_role,
                      role_permissions: idTokenResult.claims.role_permissions
                    },
                    server_key,
                    client_key,
                  }),
                );
                idTokenResult.claims.role_permissions.length === 0
                ?
                navigate('/orders')
                :
                (idTokenResult.claims.role_permissions.find(perms=>perms.adminModuleName ==='Orders')) ? 
                  navigate('/orders')
                :
                navigate(navItems[0].pages.find(page => page.title === idTokenResult.claims.role_permissions[0].adminModuleName).href);
              })
                
              })
              .catch(async (err) => {
                await dSignOut(auth);
                if (err.statusCode === 401) {
                  setMessage(
                    'Invalid user, please login with valid user or contact support.',
                  );
                  setOpen(true);
                } else {
                  setMessage(err);
                  setOpen(true);
                }
              });
          }
        } else {
          await axios
            .post('/users/create-admin', { code: code })
            .then(async (res) => {
              if (res.data.success !== false) {
                localStorage.setItem('accessToken', user.user.accessToken);
                const accessToken = window.localStorage.getItem('accessToken');
                if (accessToken) {
                  await axios
                    .post('/users/verify-admin')
                    .then((response) => {
                      const { server_key, client_key } = response?.data;
                      // setProfile(null);
                      setSignCheck(false);
                      auth.currentUser.getIdToken(true)
                        .then((idToken)=> {
                          localStorage.setItem('accessToken', idToken);
                          var tok= idToken.toString().split(".")[1]
                          var data=Buffer.from(tok,'base64')
                          data=JSON.parse(data.toString())
                      dispatch(
                        actions.INITIALIZE({
                          isAuthenticated: true,
                          user: {
                            email: user.user.email,
                            uid: user.user.uid,
                            displayName: user.user.displayName,
                            avatar: user.user.photoURL,
                                role:data.user_role,
                                role_permissions: data.role_permissions

                          },
                          server_key,
                          client_key,
                        }),
                          
                        )
                        data.role_permissions.length === 0
                        ?
                        navigate('/orders')
                        :
                        navigate(navItems[0].pages.find(page => page.title === data.role_permissions[0].adminModuleName).href);
                      
                      })
                      
                    })
                    .catch(async (err) => {
                      await dSignOut(auth);
                      if (err.statusCode === 401) {
                        setMessage('invalid user');
                        setOpen(true);
                      } else {
                        setMessage(err.message);
                        setOpen(true);
                      }
                    });
                }
              } else {
                setMessage('invalid user');
                setOpen(true);
                await dSignOut(auth);
              }
            })
            .catch(async (err) => {
              await dSignOut(auth);
              if (err.statusCode === 401) {
                setMessage('invalid user');
                setOpen(true);
              } else {
                setMessage(err.message);
                setOpen(true);
              }
            });
        }
      } else {
        if (code === null) {
          await dSignOut(auth);
        } else {
          await axios
            .post('/users/create-admin', { code: code })
            .then(async (res) => {
              if (res.data.success !== false) {
                localStorage.setItem('accessToken', user.user.accessToken);
                const accessToken = window.localStorage.getItem('accessToken');
                
                if (accessToken) {
                  await axios
                    .post('/users/verify-admin')
                    .then((response) => {
                      const { server_key, client_key } = response?.data;
                      // setProfile(null);
                      dispatch(
                        actions.INITIALIZE({
                          isAuthenticated: true,
                          user: {
                            email: user.user.email,
                            uid: user.user.uid,
                            displayName: user.user.displayName,
                            avatar: user.user.photoURL,

                          },
                          server_key,
                          client_key,
                        }),
                      );
                      navigate('/orders');
                    })
                    .catch(async (err) => {
                      await dSignOut(auth);
                      if (err.statusCode === 401) {
                        setMessage('invalid user');
                        setOpen(true);
                      } else {
                        setMessage(err.message);
                        setOpen(true);
                      }
                    });
                }
              } else {
                setMessage('invalid user');
                setOpen(true);
                await dSignOut(auth);
              }
            })
            .catch(async (err) => {
              await dSignOut(auth);
              if (err.statusCode === 401) {
                setMessage('invalid user');
                setOpen(true);
              } else {
                setMessage(err.message);
                setOpen(true);
              }
            });
        }
      }
    });
  };

  const signOut = async () => {
    await dSignOut(auth);
  };
  return (
    <AuthContext.Provider
      value={{
        user: {
          id: userState.uid,
          email: userState.email,
          displayName: userState.displayName,
          avatar: userState.avatar,
          role: userState.role || 'SuperAdmin',
        },
        method: 'firebase',
        signInWithGoogle,
        signOut,
      }}
    >
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
