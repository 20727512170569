import React from "react";
import { matchPath } from "react-router-dom";

import SidebarNavListItem from "./SidebarNavListItem";
import SidebarNavList from "./SidebarNavList";
import { shallowEqual, useSelector } from "react-redux";

const ReduceChildRoutes = (props) => {
  
const { currentState } = useSelector(
  (state) => ({ currentState: state.auth }),
  shallowEqual
);
  const { items, page, depth, currentRoute, handleDrawerToggle } = props;
  if(currentState.user.role==="super_admin" || currentState.user.role_permissions.length === 0 ||currentState.user.role_permissions.filter((role)=>role.adminModuleName===page.title).length!==0){
  if (page.children) {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute
        )
      : false;

    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
      >
        <SidebarNavList
          depth={depth + 1}
          pages={page.children}
          handleDrawerToggle={handleDrawerToggle}
        />
      </SidebarNavListItem>
    );
  } else {
    items.push(
      <SidebarNavListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        title={page.title}
        handleDrawerToggle={handleDrawerToggle}
      />
    );
  }
}

  return items;
};

export default ReduceChildRoutes;
