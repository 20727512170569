import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialItemPhotoState = {
  listLoading: true,
  actionsLoading: false,
  searchLoading: false,
  totalCount: 0,
  defaultRows: 10,
  entities: null,
  URL: '',
};

export const callTypes = {
  list: 'list',
  action: 'action',
  search: 'search',
};

export const itemPhotosSlice = createSlice({
  name: 'itemPhotos',
  initialState: initialItemPhotoState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.search) {
        state.searchLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.searchLoading = false;
      state.listLoading = false;
      state.actionsLoading = false;
    },

    FetchedItemPhotos: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    SearchItemPhotos: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.searchLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // Default Rows
    itemsRowsNoUpdate: (state, action) => {
      state.defaultRows = action.payload.rows;
    },

    ItemPhotoCreated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.itemIdBrink === action.payload.itemphoto.itemIdBrink) {
          let entityObj = _.cloneDeep(entity);
          entityObj.itemphoto = {...entityObj.itemphoto, ...action.payload.itemphoto};
          return entityObj;
        }
        return entity;
      });
    },

    ItemPhotoUPdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.itemIdBrink === action.payload.itemphoto.itemIdBrink) {
          let entityObj = _.cloneDeep(entity);
          entityObj.itemphoto = {...entityObj.itemphoto, ...action.payload.itemphoto};
          return entityObj;
        }
        return entity;
      });
    },

    itemPhotoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.itemIdBrink === action.payload.id) {
          let entityObj = _.cloneDeep(entity);
          entityObj.itemphoto = {};
          return entityObj;
        }
        return entity;
      });
    },

    createURL: (state, action) => {
      state.actionsLoading = false;
      const { URL } = action.payload;

      state.URL = URL;
    },
  },
});
