import { createSlice } from '@reduxjs/toolkit';

const initialLocationsState = {
  listLoading: false,
  actionsLoading: false,
  actionUploading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  searchLoading: false,
  defaultRows: 10,
};
export const callTypes = {
  list: 'list',
  action: 'action',
  search: 'search',
  upload: 'upload',
};

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState: initialLocationsState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.search) {
        state.searchLoading = true;
      } else if (action.payload.callType === callTypes.upload) {
        state.actionUploading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.searchLoading = false;
      state.listLoading = false;
      state.actionsLoading = false;
    },
    recordFetched: (state, action) => {
      state.actionsLoading = false;
      state.customerForEdit = action.payload.customerForEdit;
      state.error = null;
    },
    recordsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    searchRecords: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.searchLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    recordCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.location);
    },
    recordUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.actionUploading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.idCampaign === action.payload.postObj.idCampaign) {
          return action.payload.postObj;
        }
        return entity;
      });
    },
    recordDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.actionUploading = false;
      state.entities = state.entities.filter(
        (el) => el.idCampaign !== action.payload.id,
      );
    },
    recordsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.idCampaign),
      );
    },
    // Default Rows
    itemsRowsNoUpdate: (state, action) => {
      state.defaultRows = action.payload.rows;
    },
  },
});
