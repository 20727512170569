import { createSlice } from '@reduxjs/toolkit';

const initialPreptimeState = {
  listLoading: false,
  actionsLoading: false,
  defaultRows: 10,
  totalCount: 0,
  entities: null,
  lastError: null,
};
export const callTypes = {
  list: 'list',
  action: 'action',
};

export const preptimesSlice = createSlice({
  name: 'preptimes',
  initialState: initialPreptimeState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // PreptimeFetched
    preptimesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // PreptimeCreated
    preptimeCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.preptime);
    },
    // PreptimeUpdated
    preptimeUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.idPrepTime === action.payload.preptime.idPrepTime) {
          return action.payload.preptime;
        }
        return entity;
      });
    },
    // PreptimeDeleted
    preptimeDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.idPrepTime !== action.payload.id,
      );
    },
    // Default Rows
    itemsRowsNoUpdate: (state, action) => {
      state.defaultRows = action.payload.rows;
    },
  },
});
