import { createSlice } from '@reduxjs/toolkit';
//import _ from 'lodash';

const initialState = {
  listLoading: true,
  actionsLoading: false,
  searchLoading: false,
  totalCount: 0,
  defaultRows: 20,
  entities: [],
  items: [],
  URL: '',
};

export const callTypes = {
  list: 'list',
  action: 'action',
  search: 'search',
};

export const suggestionsSlice = createSlice({
  name: 'suggestions',
  initialState: initialState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.search) {
        state.searchLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.searchLoading = false;
      state.listLoading = false;
      state.actionsLoading = false;
    },

    fetchedsuggestions: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    itemsFetched: (state, action) => {
      state.actionsLoading = false;
      state.items = action.payload.entities;
    },
    // Default Rows
    itemsRowsNoUpdate: (state, action) => {
      state.defaultRows = action.payload.rows;
    },

    suggestionsCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.suggestions);
    },

    suggestionsUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (
          entity.idSuggestionItems ===
          action.payload.suggestions.idSuggestionItems
        ) {
          return action.payload.suggestions;
        }
        return entity;
      });
    },
  },
});
