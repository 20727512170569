import { FormControl, Grid, IconButton, MenuItem, Select } from "@mui/material";
import React from "react";

import {ArrowForwardIos} from '@mui/icons-material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
const TablePagination=(props)=>{

    const prevPage=()=>{
        props.onPageChange(-1,props.rowsPerPage,props.text&&props.text)
    }
    const nextPage=()=>{
        props.onPageChange(1,props.rowsPerPage,props.text&&props.text)

    }
    return(
        <>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
            {"Rows per page:"}
            <FormControl>
            <Select
                sx={{margin:"2rem"}}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.rowsPerPage}
                onChange={props.onRowsPerPageChange}
            >
                {props.rowsPerPageOptions.map((option,index)=><MenuItem key={index} value={option}>{option}</MenuItem>)}
            </Select>
            </FormControl>
            <IconButton disabled={props.page<2} onClick={()=>prevPage()}>
                <input hidden/>
                <ArrowBackIosNewIcon />
            </IconButton>
            <IconButton disabled={props.rowsPerPage!==props.count} onClick={()=>nextPage()}>
                <input hidden/>
                <ArrowForwardIos />
            </IconButton>
            
            </Grid>
        </>
    )
}

export default TablePagination;