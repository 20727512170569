import React from 'react';
import { useRoutes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { create } from 'jss';
import { ThemeProvider } from 'styled-components/macro';

import { StyledEngineProvider } from '@mui/styled-engine-sc';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StylesProvider from '@mui/styles/StylesProvider';
import jssPreset from '@mui/styles/jssPreset';

import createTheme from './theme';
import routes from './routes';

import useTheme from './hooks/useTheme';

import { AuthProvider } from './contexts/FirebaseAuthContext';

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point'),
});
function App({ store }) {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  //var token = store.getState().auth.authDate;
  var token = window.localStorage.getItem('accessToken') || '';
  if (
    (token === '' || store.getState().auth.isAuthenticated === false) &&
    window.location.pathname !== '/'
  ) {
    window.location = '/';
    return false;
  }

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | Mixt Admin Panel"
        defaultTitle="Mixt Admin Panel"
      />
      <Provider store={store}>
        <StylesProvider jss={jss}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={createTheme(theme)}>
                <ThemeProvider theme={createTheme(theme)}>
                  <AuthProvider>{content}</AuthProvider>
                </ThemeProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </StylesProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
