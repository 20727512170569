import axios from '../../utils/axios';
import _ from 'lodash';

export const BASE_URL = '/users';

export function getAllModules() {
  return axios.get(`${BASE_URL}/admin-modules`);
}
export function getAllRole() {
  return axios.get(`${BASE_URL}/admin-roles`);
}

export function createRole(postData) {
  let postvalues = _.cloneDeep(postData);
  return axios.post(`${BASE_URL}/admin-roles`, postvalues);
}
export function updateRole(postData) {
  let postvalues = _.cloneDeep(postData);
  return axios.patch(`${BASE_URL}/admin-roles/${postData.id}`, postvalues);
}
