import { createSlice } from '@reduxjs/toolkit';

const initialUserState = {
  listLoading: false,
  actionsLoading: false,
  amount: [],
  door_dash: [],
  error:""
};
export const callTypes = {
  list: 'list',
  action: 'action',
};

export const deliveryamountSlice = createSlice({
  name: 'deliveryamount',
  initialState: initialUserState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // AmountFetched
    AmountFetched: (state, action) => {
      const {  amount } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.amount = amount;
    },
    // DoorDashFetched
    DoorDashFetched: (state, action) => {
      const {  amount } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.door_dash = amount;
    },
    // AmountPost
    AmountPost: (state, action) => {
        const {  amount } = action.payload;
        state.listLoading = false;
        state.actionsLoading = false;
        state.error = null;
        state.amount.settingValue = amount.settingValue;
    },
    // DoorDashPost
    DoorDashPost: (state, action) => {
        const {  amount } = action.payload;
        state.listLoading = false;
        state.actionsLoading = false;
        state.error = null;
        state.amount.doordash_minimum_delivery = amount.settingValue;
    }
  },
});
