import React from "react";


import {
  TableCell,
  Typography,
} from "@mui/material";
import moment from "moment";


export default function RowEdit({
  row
}) {
  var date=moment(row.updatedAt).format('DD/M/YYYY - HH:mm:ss');


  return (
        <>
          <TableCell align="left">
            <span>
              <Typography
                textOverflow="ellipsis"               
                overflow="hidden"
                whiteSpace="nowrap"
              >
             {row.email}
              </Typography>
            </span>
          </TableCell>
          <TableCell align="left">
            <span>
              <Typography
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
              >
               {date}
              </Typography>
            </span>
          </TableCell>
        
        </>
  );
}
